import create from 'zustand';
import type { TStore } from "../react-app-env";

export const useStoreStore = create<{
  store?: TStore;
  // eslint-disable-next-line no-unused-vars
  setStore: (store: TStore) => void;
}>((set) => ({
  setStore: (store) => set(() => ({ store })),
}));

