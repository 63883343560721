import create from 'zustand';
import type { TRegion } from "../react-app-env";

export const useRegionsStore = create<{
  regions: TRegion[];
  // eslint-disable-next-line no-unused-vars
  setRegions: (regions: TRegion[]) => void;
}>((set) => ({
  regions: [],
  setRegions: (regions) => set(() => ({ regions })),
}));

