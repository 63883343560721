import type { ReactNode } from 'react';
import React from 'react';
import { CloseOutline } from 'antd-mobile-icons';
import { Modal } from 'antd-mobile';
import type { ModalProps } from 'antd-mobile/es/components/modal';

type GeneralModalProps = {
  contentTitle?: string; // contentTitle
  children?: ReactNode;
  onClose?: () => void;
};

function GeneralModal(props: GeneralModalProps & ModalProps) {
  const { contentTitle, children, onClose } = props;
  return (
    <Modal
      closeOnMaskClick
      {...props}
      content={
        <>
          <div className="px-5 py-3">
            {contentTitle && <h3 className="font-bold text-2xl mb-4">{contentTitle}</h3>}
            {children}
          </div>
          <button
            onClick={() => onClose?.()}
            type="button"
            className="absolute -bottom-12 mx-auto left-0 right-0  w-7 h-7 rounded-full bg-white flex items-center justify-center"
          >
            <CloseOutline className="text-gray-600" />
          </button>
        </>
      }
    />
  );
}
export default GeneralModal;
