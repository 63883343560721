import { Stepper, Toast } from 'antd-mobile';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { TCartProduct } from '../stores/cart';
import { useCartStore } from '../stores/cart';
import { useProductsStore } from "../stores/products";
import { calcSpecsTotal } from '../utils';
import type { SkuCardUIProps } from './SkuCardUI';
import SkuCardUI from './SkuCardUI';

type SkuCartCardProps = {
  smallImage?: boolean; // 如订单列表应该启用该项
  cartProduct: TCartProduct;
  soldout?: boolean;
  featured?: boolean; // 开启后价格变红
  stepper?: (quantity: number) => ReactNode; // 用于控制 stepper 部分
  uiProps?: SkuCardUIProps;
};

function SkuCartCard({
  cartProduct,
  featured = false,
  smallImage = false,
  soldout = false,
  stepper,
  uiProps = {},
}: SkuCartCardProps) {
  const { getProductByIdCart } = useProductsStore((state) => ({
    productsCart: state.productsCart,
    getProductByIdCart: state.getProductByIdCart,
  }));
  const { cart, setCart } = useCartStore((state) => ({
    cart: state.cart,
    setCart: state.setCart,
  }));

  const product = getProductByIdCart(cartProduct.id);
  const { cover, title, status, specification } = product;
  const quantity = cart.filter((p) => JSON.stringify(p) === JSON.stringify(cartProduct)).length;

  const handleAdd = () => {
    if (status === 'soldout') {
      Toast.show('该商品暂时售罄，无法下单');
      return;
    }
    setCart([...cart, { ...cartProduct }]);
  };

  const handleRemove = () => {
    let removedOnce = false;
    setCart(
      cart.filter((c) => {
        if (JSON.stringify(c) === JSON.stringify(cartProduct)) {
          if (removedOnce) return true;
          removedOnce = true;
          return false;
        }
        return JSON.stringify(c) !== JSON.stringify(cartProduct);
      }),
    );
  };

  const handleStepperOnChange = (value: number) => {
    if (value < quantity) {
      handleRemove();
    }
    if (value > quantity) {
      handleAdd();
    }
  };

  const content = useMemo(() => {
    const fieldsValue = cartProduct.values;
    if (!fieldsValue || Object.keys(fieldsValue).length === 0) return product.content;

    const selectedSpecsText: any[] = [];
    Object.entries(fieldsValue).forEach(([key, values]) => {
      const child = specification.find((s) => s.id === +key)?.child;
      if (Array.isArray(values)) {
        selectedSpecsText.push(...values.map((v) => child?.find((c) => c.id === +v)?.name));
      }
    });
    return `已选：${selectedSpecsText.join('、')}`;
  }, [cartProduct, product.content, specification]);

  const price = useMemo(() => {
    return +product.price + calcSpecsTotal(product.specification, cartProduct.values);
  }, [cartProduct, product]);

  return (
    <SkuCardUI
      cover={cover}
      soldOut={soldout || product.status === 'soldout'}
      title={title}
      content={content}
      price={price}
      smallImage={smallImage}
      featured={featured}
      priceRight={
        stepper?.(quantity) || (
          <Stepper value={quantity} inputReadOnly step={1} onChange={handleStepperOnChange} />
        )
      }
      {...uiProps}
    />
  );
}
export default SkuCartCard;
