import React from 'react';
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import dayjs from "dayjs";
import { getOrderDetailService } from "../../services";
import SkuCardUI from "../../components/SkuCardUI";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import HeaderBar from "../../components/HeaderBar";
import { getOrderStatusText } from "../../utils";

function OrderDetail() {
  const { outTradeNo } = useParams();
  const { data, loading } = useRequest(
    () => getOrderDetailService({ out_trade_no: outTradeNo! }),
    {
      ready: typeof outTradeNo !== "undefined",
    }
  );
  const order = data?.data;
  return (
    <div className="others">
      <HeaderBar>详细订单</HeaderBar>
      <div className="bg-gray-100 min-h-screen py-4">
        {loading || !order ? (
          <LoadingSkeleton />
        ) : (
          <>
            <section className="space-y-4 py-4 bg-white mb-3">
              <div className="px-4 flex justify-between text-xs">
                <div className="leading-6">
                  <h3 className="font-bold text-lg mb-1">{order.region[0]}</h3>
                  <h3 className="font-bold text-lg">{order?.address}</h3>
                  <p className="space-x-4 mt-2">
                    <span>{order?.name}</span>{" "}
                    {order?.phone && <span>手机号：{order?.phone}</span>}
                    {order?.tg && <span>TG：{order.tg}</span>}
                  </p>
                </div>
              </div>
            </section>
            <section className="space-y-4 p-4 bg-white">
              <div className="flex justify-between text-xs">
                <time>
                  {dayjs(order?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </time>
                <span>{getOrderStatusText(order.status)}</span>
              </div>
              <div className="space-y-6">
                {Array.isArray(order.detail) ? (
                  order.detail.map(
                    ({ quantity, title, cover, price, values, id }) => {
                      return (
                        <SkuCardUI
                          key={[id, title, JSON.stringify(values)].join("")}
                          title={title}
                          cover={cover}
                          content={
                            values && values.length > 0
                              ? `已选：${values
                                  .map((v: any) =>
                                    v.items.map((i: any) => i.name)
                                  )
                                  .flat()
                                  .join("、")}`
                              : ""
                          }
                          price={price}
                          smallImage
                          priceRight={
                            <span className="text-xs">x{quantity}</span>
                          }
                        />
                      );
                    }
                  )
                ) : (
                  <p className="text-center my-4 text-base text-gray-600">
                    无法获取订单信息
                  </p>
                )}
              </div>
              <p className="text-right text-xs">
                <>
                  共 {order.detail.length} 件，商品小计：
                  <span className="font-bold text-lg">
                    <span className="text-2xs">P</span>
                    {order.subtotal}
                  </span>
                </>
              </p>
            </section>
            <section className="cell-group">
              <div className="cell">
                <div>订单号码</div>
                <div>{order.out_trade_no}</div>
              </div>
              <div className="cell">
                <div>订单时间</div>
                <time>
                  {dayjs(order?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </time>
              </div>
              <div className="cell">
                <div>订单备注</div>
                <div>{order.user_comment}</div>
              </div>
            </section>
            <section className="cell-group">
              <div className="cell">
                <div>配送费用</div>
                <div className="text-xsm">
                  {order.old_delivery_fee > 0 &&
                    order.old_delivery_fee !== order.delivery_fee && (
                      <span className="text-gray-400 line-through">
                        P{order.old_delivery_fee || 0}
                      </span>
                    )}
                  <span className="font-bold">P{order.delivery_fee || 0}</span>
                </div>
              </div>
              {order.extra?.original_subtotal && (
                <div className="cell">
                  <div>优惠前合计</div>
                  <div className="font-bold">
                    P
                    {(
                      Number(order.extra?.original_subtotal) +
                      Number(order.delivery_fee)
                    ).toFixed(2)}
                  </div>
                </div>
              )}
              <div className="cell">
                <div>订单合计</div>
                <div className="font-bold">
                  P
                  {(
                    Number(order.subtotal) + Number(order.delivery_fee)
                  ).toFixed(2)}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default OrderDetail;
