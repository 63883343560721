import { Home } from '@icon-park/react';
import { NavBar } from 'antd-mobile';
import type { NavBarProps } from 'antd-mobile/es/components/nav-bar';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

type HeaderBarProps = {
	children?: ReactNode;
	shadow?: boolean;
	className?: string;
	navbarProps?: NavBarProps;
};

function HeaderBar({ children, shadow = false, className, navbarProps }: HeaderBarProps) {
	const navigate = useNavigate();
	return (
		<NavBar
			back=""
			onBack={() => navigate(-1)}
			className={classNames(
				'header-bar shadow font-bold top-0 sticky z-10 bg-white w-full',
				className,
			)}
			right={
				<div className="flex items-center justify-end">
					<Home
						className="flex items-center"
						theme="outline"
						size="24"
						onClick={() => navigate('/')}
					/>
				</div>
			}
			style={{
				'--height': '50px',
			}}
			{...navbarProps}
		>
			{children}
		</NavBar>
	);
}
export default HeaderBar;
