import { useRequest } from 'ahooks';
import { Button, Toast } from 'antd-mobile';
import React, { useEffect, useState } from "react";
import { sendUserVerificationService } from '../services';

function SendVerificationCodeButton(props: { onSent?: () => void; phone?: string }) {
  const { onSent, phone } = props;
  const [countdown, setCountdown] = useState<number>(0);
  const { loading, run: send } = useRequest(sendUserVerificationService, {
    manual: true,
    onSuccess({ status, msg }) {
      if (status !== 200) {
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        return;
      }
      onSent?.();
      setCountdown(60);
      Toast.show({
        icon: 'success',
        content: '发送成功',
      });
    },
  });

  useEffect(() => {
    if (!countdown) return;

    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  return (
    <Button
      onClick={() => send({ phone: phone || undefined })}
      disabled={loading || countdown > 0}
      className="bg-blue-100 border-blue-100 text-blue-600 font-bold whitespace-nowrap px-8"
      {...props}
    >
      {countdown > 0 ? `${countdown} 秒` : '获取验证码'}
    </Button>
  );
}

export default SendVerificationCodeButton;
