import { sum } from "lodash";
import { TCartProductWithDetail } from "../react-app-env";
import { useStoreStore } from "../stores/store";
import SkuCardUI from "./SkuCardUI";

function SkuCardsWithSubtotal({ data }: { data: TCartProductWithDetail[] }) {
  const { store } = useStoreStore();
  const subtotal = sum(data.map((d) => +d.price * d.quantity));
  const discount = store?.extra.discount || 100;
  const discountFee = +subtotal * ((100 - discount) / 100);

  return (
    <>
      {data?.length > 0 ? (
        <>
          {data.map(
            ({
              quantity,
              title,
              cover,
              price,
              values,
              id,
            }: TCartProductWithDetail) => {
              return (
                <SkuCardUI
                  key={[id, title, JSON.stringify(values)].join("")}
                  title={title}
                  cover={cover}
                  content={
                    values && values.length > 0
                      ? `已选：${values
                          .map((v) => v.items.map((i) => i.name))
                          .flat()
                          .join("、")}`
                      : ""
                  }
                  price={price}
                  smallImage
                  priceRight={<span className="text-xs">x{quantity}</span>}
                />
              );
            }
          )}
          <p className="text-right text-xs">
            共 {sum(data.map((d) => d.quantity))} 件，商品小计：
            <span className="text-red-600 font-bold">
              <span className="text-2xs">P</span>
              {(+subtotal - discountFee).toFixed(2)}
            </span>
          </p>
          {discount < 100 && (
            <p className="text-red-500 text-xs text-right font-bold">
              已优惠 <span className="text-2xs">P</span>
              {discountFee.toFixed(2)}
            </p>
          )}
        </>
      ) : (
        <p className="text-center my-4 text-base text-gray-600">暂无商品</p>
      )}
    </>
  );
}

export default SkuCardsWithSubtotal;
