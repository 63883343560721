import create from "zustand";
import { TUser } from "../react-app-env";

export const useCurrentUserStore = create<{
  user?: TUser;
  loginModalVisible: boolean;
  setLoginModalVisible: (visible: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setUser: (user: TUser) => void;
  refreshUserAsync: () => void;
  setRefreshUserAsync: (refreshUserAsync: () => void) => void;
  setRequesting: (requesting: boolean) => void;
  requesting: boolean;
}>((set) => ({
  setRequesting: (requesting) => set(() => ({ requesting })),
  requesting: true,
  loginModalVisible: false,
  setUser: (user) => set(() => ({ user })),
  setLoginModalVisible: (loginModalVisible) =>
    set(() => ({ loginModalVisible })),
  refreshUserAsync: async () => {},
  setRefreshUserAsync: (refreshUserAsync) => set(() => ({ refreshUserAsync })),
}));
