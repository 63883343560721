import classNames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

type FooterBarProps = {
	children?: ReactNode;
	shadow?: boolean;
	className?: string;
};

function FooterBar({ children, shadow = false, className }: FooterBarProps) {
	return (
		<footer
			style={{
				background: "rgba(255, 255, 255, 0.9)",
			}}
			className={classNames(
				"fixed w-full left-0 bottom-0 p-2 pb-8 bottom-bar",
				shadow ? "shadow-lg" : "",
				className
			)}
		>
			{children}
		</footer>
	);
}

export default FooterBar;
