import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, NavBar, TextArea, Toast} from 'antd-mobile';
import {RightOutline} from 'antd-mobile-icons';
import GeneralModal from '../components/GeneralModal';
import {useCurrentUserStore} from '../stores/user';
import {useNavigate} from 'react-router-dom';
import {useCartStore} from '../stores/cart';
import {useAddressBookStore} from '../stores/addressBook';
import SendVerificationCodeButton from '../components/SendVerificationButton';
import {useRequest} from 'ahooks';
import {getVerificationTokenService, modifyPhoneService, userOutLogin} from '../services';
import create from 'zustand';
import HeaderBar from '../components/HeaderBar';
import {useGroupBuyStore} from '../stores/groupbuy';

const useModifyPhoneStore = create<{
    veriModalVisible: boolean;
    setVeriModalVisible: (veriModalVisible: boolean) => void;
    bindModalVisible: boolean;
    setBindModalVisible: (bindModalVisible: boolean) => void;
    token: string;
    setToken: (token: string) => void;
}>((set) => ({
    veriModalVisible: false,
    setVeriModalVisible: (veriModalVisible: boolean) => set(() => ({veriModalVisible})),
    bindModalVisible: false,
    setBindModalVisible: (bindModalVisible: boolean) => set(() => ({bindModalVisible})),
    token: '',
    setToken: (token: string) => set(() => ({token})),
}));

function BindModal({visible}: { visible: boolean }) {
    const {setBindModalVisible, token} = useModifyPhoneStore();
    const refreshUserAsync = useCurrentUserStore((state) => state.refreshUserAsync);
    const {run: bind, loading} = useRequest(modifyPhoneService, {
        manual: true,
        async onSuccess({status, msg}) {
            if (status !== 200) {
                Toast.show({
                    icon: 'fail',
                    content: msg,
                });
                return;
            }
            await refreshUserAsync();
            setBindModalVisible(false);
            Toast.show({
                icon: 'success',
                content: msg,
            });
        },
    });

    useEffect(() => {
        if (!token) setBindModalVisible(false);
    }, [token, setBindModalVisible]);
    const [phone, setPhone] = useState<string>('');

    return (
        <GeneralModal
            onClose={() => setBindModalVisible(false)}
            visible={visible}
            contentTitle="新手机"
        >
            <Form
                onFinish={(values) => {
                    bind({
                        ...values,
                        token,
                    });
                }}
            >
                <div className="space-y-4">
                    <Form.Item noStyle label="新手机号" name="phone" required>
                        <Input
                            onChange={(e) => setPhone(e)}
                            placeholder="输入新的手机号"
                            className="bg-gray-200 px-4 py-3"
                        />
                    </Form.Item>
                    <div className="flex space-x-2 justify-between">
                        <Form.Item noStyle name="code" required>
                            <Input placeholder="输入验证码" className="bg-gray-200 px-4 py-3" type="text"/>
                        </Form.Item>
                        <SendVerificationCodeButton phone={phone}/>
                    </div>
                    <Button block color="primary" loading={loading} type="submit" className="font-bold py-3">
                        确认修改
                    </Button>
                </div>
            </Form>
        </GeneralModal>
    );
}

function VeriModal({visible}: { visible: boolean }) {
    const [called, setCalled] = useState<boolean>(false);
    const user = useCurrentUserStore((state) => state.user);
    const {setToken, setVeriModalVisible, setBindModalVisible} = useModifyPhoneStore();
    const {run: getToken, loading} = useRequest(getVerificationTokenService, {
        manual: true,
        onSuccess({status, msg, data}) {
            if (status !== 200) {
                Toast.show({
                    icon: 'fail',
                    content: msg,
                });
                return;
            }
            setToken(data.token);
            setVeriModalVisible(false);
            setBindModalVisible(true);
            Toast.show({
                icon: 'success',
                content: msg,
            });
        },
    });

    return (
        <GeneralModal
            visible={visible}
            onClose={() => setVeriModalVisible(false)}
            contentTitle="安全验证"
        >
            {called && (
                <p className="mb-4">为了保护你的账号安全，短信验证码已发送至 {user?.phone}，请注意查收。</p>
            )}
            <Form
                onFinish={(values) => {
                    getToken({
                        ...values,
                        phone: user?.phone,
                    });
                }}
            >
                <div className="space-y-4">
                    <div className="flex space-x-2 justify-between">
                        <Form.Item noStyle name="code">
                            <Input placeholder="输入验证码" className="bg-gray-200 px-4 py-3" type="text"/>
                        </Form.Item>
                        <SendVerificationCodeButton onSent={() => setCalled(true)}/>
                    </div>
                    <Button block color="primary" loading={loading} type="submit" className="font-bold py-3">
                        下一步
                    </Button>
                </div>
            </Form>
        </GeneralModal>
    );
}

function Me() {
    const {user, setUser} = useCurrentUserStore();
    const setCart = useCartStore((state) => state.setCart);
    const setAddresses = useAddressBookStore((state) => state.setAddresses);
    const {setCurrentGroupBuyOutTradeNo, setDataKey} = useGroupBuyStore();
    const navigate = useNavigate();

    const logout = () => {
        Modal.confirm({
            content: '您确定要退出登录吗？',
            onConfirm: () => {
                // userOutLogin()
                //     .then(({status, msg}) => {
                //         if (status === 200) {
                localStorage.removeItem('takeaway-address-book');
                localStorage.removeItem('takeaway-cart');
                localStorage.removeItem('takeaway-groupbuy');
                localStorage.removeItem('user-token');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
                Toast.show({
                    icon: 'success',
                    content: '登出成功',
                });
                //     } else {
                //         Toast.show({
                //             icon: 'error',
                //             content: msg,
                //         });
                //     }
                // })
                // .catch(() => {
                //     Toast.show({
                //         icon: 'error',
                //         content: '网络错误，请稍后再试',
                //     });
                // });
            },
        });
    };

    const {veriModalVisible, setVeriModalVisible, bindModalVisible, setBindModalVisible} =
        useModifyPhoneStore();

    return (
        <div className="others">
            <HeaderBar>我的</HeaderBar>
            <div className="bg-gray-100 min-h-screen space-y-4">
                <div className="p-4 cell-group">
                    <div className="flex flex-col items-center">
                        <div className="w-14 h-14 rounded-full bg-gray-200 flex justify-center items-center">
              <span className="text-2xl font-bold text-gray-600">
                {`${(user?.phone || user?.username)?.[0]}`.toUpperCase()}
              </span>
                        </div>
                        <span className="font-bold mt-2">{user?.phone || user?.username}</span>
                    </div>
                </div>
                <div className="cell px-4 bg-white" onClick={() => navigate('/orders')}>
                    <div>我的订单</div>
                    <div className="font-bold flex items-center space-x-2">
                        <span className="text-sm">{user?.orders_count}</span>
                        <RightOutline width={20} height={20} className="text-gray-500"/>
                    </div>
                </div>

                <div
                    className="cell px-4 bg-white"
                    onClick={() => {
                        if (user?.phone) setVeriModalVisible(true);
                        else setBindModalVisible(true);
                    }}
                >
                    <div>{user?.phone ? '修改手机' : '绑定手机'}</div>
                    <RightOutline width={20} height={20} className="text-gray-500"/>
                </div>
                <div className="cell px-4 bg-white" onClick={() => logout()}>
                    <div>退出登录</div>
                    <RightOutline width={20} height={20} className="text-gray-500"/>
                </div>
            </div>
            <VeriModal visible={veriModalVisible}/>
            <BindModal visible={bindModalVisible}/>
        </div>
    );
}

export default Me;
