import { Popup } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import { useEffect } from 'react';
import { useCartStore } from '../stores/cart';
import { useProductsStore } from "../stores/products";
import { getValuesDetail } from '../utils';
import CheckoutBar from './CheckoutBar';
import SkuCartCard from './SkuCartCard';

function CartDetailPopup() {
  const { cart, popupVisible, setPopupVisible } = useCartStore();
  const { getProductByIdCart} = useProductsStore();

  useEffect(() => {
    if (cart.length === 0) setPopupVisible(false);
  }, [cart, setPopupVisible]);

  return (
    <Popup
      visible={popupVisible}
      onMaskClick={() => setPopupVisible(false)}
      className={classNames(popupVisible ? 'popup-visible' : 'popup-invisible', 'z-20 relative')}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div>
          <h3 className="font-bold text-sm">已选商品</h3>
          <button
            type="button"
            className="bg-gray-500 rounded-full p-1 absolute right-4 top-4"
            onClick={() => setPopupVisible(false)}
            style={{
              lineHeight: 0,
            }}
          >
            <CloseOutline color="#fff" fontSize={16} />
          </button>
        </div>
        <div className="py-4 max-h-66vh overflow-y-scroll space-y-6 mb-24 scrollbar-hide">
          {uniqBy(cart, (c) => JSON.stringify(c)).map((c) => {
            const soldAvailable = (({ id, values }) => {
              const product = getProductByIdCart(id);
              return (
                Object.entries(getValuesDetail(product, values)).every(([specId, childs]) => {
                  const spec = product?.specification.find((s) => s.id === +specId);
                  if (typeof childs === 'undefined' || childs?.length === 0)
                    return product?.status === 'on' && !spec?.attribute.includes('required');
                  // 规格售罄
                  return childs?.every(
                    (child) =>
                      child?.status === 'on' ||
                      (child?.status === 'inherit' && child.inherit_status === 'on'),
                  );
                }) && product?.status === 'on'
              );
            })(c);
            return (
              <SkuCartCard
                key={JSON.stringify(c)}
                featured
                smallImage
                soldout={!soldAvailable}
                cartProduct={c}
              />
            );
          })}
        </div>
      </div>
      <div className="w-full flex justify-between hidden">
        <CheckoutBar nonInteractive />
      </div>
    </Popup>
  );
}

export default CartDetailPopup;
