import create from 'zustand';
import type { TProduct } from "../react-app-env";

export const useProductsStore = create<{
  products: TProduct[];
  productsCart: TProduct[];
  // eslint-disable-next-line no-unused-vars
  setProducts: (products: TProduct[]) => void;
  getProductById: (id: number | string) => TProduct;
  setProductsCart: (productsCart: TProduct[]) => void;
  getProductByIdCart: (id: number | string) => TProduct;
}>((set, get) => ({
  products: [],
  productsCart:[],
  setProducts: (products) => set(() => ({ products })),
  getProductById: (id: number | string) => {
    const products = get().products;
    return (
      products.find((p) => p.id === +id) || {
        id: 0,
        title: "失效商品",
        content: "商品已失效，请刷新页面",
        cover: "",
        price: "0",
        status: "off",
        thumbnails: [],
        required: "no",
        category: {
          id: 0,
          name: "失效商品",
          orderby: 0,
        },
        parent_category: {
          id: 0,
          name: "失效商品",
          orderby: 0,
        },
        specification: [],
		category_discount:0,
		discount:0,
		goods_discount:0,
		category_discount_control:false,
		discount_control:false,
		goods_discount_control:false,
      }
    );
  },
  setProductsCart: (productsCart) => set(() => ({ productsCart })),
  getProductByIdCart: (id: number | string) => {
    const productsCart = get().productsCart;
    return (
      productsCart.find((p) => p.id === +id) || {
        id: 0,
        title: "失效商品",
        content: "商品已失效，请刷新页面",
        cover: "",
        price: "0",
        status: "off",
        thumbnails: [],
        required: "no",
        category: {
          id: 0,
          name: "失效商品",
          orderby: 0,
        },
        parent_category: {
          id: 0,
          name: "失效商品",
          orderby: 0,
        },
        specification: [],
  		category_discount:0,
  		discount:0,
  		goods_discount:0,
  		category_discount_control:false,
  		discount_control:false,
  		goods_discount_control:false,
      }
    );
  },
}));

