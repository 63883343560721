import { MallBag } from '@icon-park/react';
import { useRequest } from 'ahooks';
import { Badge, Button, Skeleton, Toast } from 'antd-mobile';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { postUpdateGroupBuyOrderService } from "../services";
import { useAddressBookStore } from "../stores/addressBook";
import { useCartStore } from "../stores/cart";
import { useGroupBuyStore } from "../stores/groupbuy";
import { useProductsStore } from "../stores/products";
import { useRegionsStore } from "../stores/regions";
import { useStoreStore } from "../stores/store";
import { useCurrentUserStore } from "../stores/user";
import { getValuesDetail } from '../utils';

type TCheckoutBarProps = {
  nonInteractive?: boolean;
};

function CheckoutBar({ nonInteractive = false }: TCheckoutBarProps) {
  const navigate = useNavigate();

  const { store } = useStoreStore();
  const { productsCart, getProductByIdCart } = useProductsStore();
  const { setPopupVisible, popupVisible, cart, getCartSubTotal,getCartPreTotal } = useCartStore();
  const { currentGroupBuyOutTradeNo, setCurrentGroupBuyOutTradeNo, dataKey } = useGroupBuyStore();
  const { user } = useCurrentUserStore();
  const subtotal = useMemo(
    () => getCartSubTotal(productsCart),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCartSubTotal, productsCart, cart],
  );
  const preferential = useMemo(
    () => getCartPreTotal(productsCart),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCartSubTotal, productsCart, cart],
  );
  // const preferential = useMemo(() => {
	  // let totalnum:number = 0.0;
	  // cart.map((c) => {
		 //  products.map((cc) => {
		 //  	if (cc.id == c.id) {
			// 	let discount:number = 100;
			// 	let price:number = 0.0;
			// 	Object.entries(cc).forEach(([k, v]) => {
			// 		if (k == 'discount' && v < 100) {
			// 			discount = Number(v);
			// 		}
			// 		if (k == 'category_discount' && v < 100) {
			// 			discount = Number(v);
			// 		}
			// 		if (k == 'goods_discount' && v < 100) {
			// 			discount = Number(v);
			// 		}
			// 		if (k == 'price') {
			// 			price = Number(v);
			// 		}
			// 	});
			// 	totalnum += Number((price * discount/100).toFixed(2));
			// }
		 //  });
	  // });
	  // totalnum = Number(totalnum.toFixed(2))
  // },[cart]);
  const { run: confirmGroupbuyOrder, loading: confirmGroupbuyOrderLoading } = useRequest(
    () =>
      postUpdateGroupBuyOrderService({
        cart,
        out_trade_no: currentGroupBuyOutTradeNo!,
        type: dataKey,
      }),
    {
      onSuccess({ status, msg, data }) {
        if (status !== 200) {
          Toast.show({
            icon: 'fail',
            content: msg,
          });
          return;
        }
        setCurrentGroupBuyOutTradeNo(data.out_trade_no);
        navigate(`/groupbuy/${data.out_trade_no}`);
      },
      manual: true,
      ready: !!currentGroupBuyOutTradeNo,
    },
  );

  // 登录用户计算配送费
  const regions = useRegionsStore((state) => state.regions);
  const { addresses: userAddresses, selectedAddressId } = useAddressBookStore();
  const minimumCharge = +(store?.extra.minimum_charge || 0);
  const { deliveryFee, oldDeliveryFee, startPrice } = useMemo(() => {
    const selectedAddress = userAddresses.find((a) => a.id === selectedAddressId);
    const region = regions.find(
      (r) => r.id === (selectedAddress ? selectedAddress?.region_id : store?.extra.base),
    );
    return {
      deliveryFee: region?.delivery_fee,
      oldDeliveryFee: region?.old_delivery_fee || 0,
      startPrice: region?.start_price || minimumCharge,
    };
  }, [regions, selectedAddressId, userAddresses, minimumCharge, store]);

  // 计算库存
  const soldAvailable = useMemo(() => {
    return cart.every(({ id, values }) => {
      const product = getProductByIdCart(id);
      return (
        Object.entries(getValuesDetail(product, values)).every(([specId, childs]) => {
          const spec = product?.specification.find((s) => s.id === +specId);
          if (typeof childs === 'undefined' || childs?.length === 0)
            return product?.status === 'on' && !spec?.attribute.includes('required');
          // 规格售罄
          return childs?.every(
            (child) =>
              child?.status === 'on' ||
              (child?.status === 'inherit' && child.inherit_status === 'on'),
          );
        }) && product?.status === 'on'
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, getProductByIdCart, productsCart]);

  const isStoreOpen = store?.status === 'open';
  const notEnoughRequiredItems = useMemo(() => {
    const cartItemsId = cart.map(({ id }) => id);
    return productsCart.filter((p) => p.required === 'yes').filter((p) => !cartItemsId.includes(p.id));
  }, [cart, productsCart]);

  const isInGroupBuyMode = dataKey && currentGroupBuyOutTradeNo;
  const requiredItemsEnough = useMemo(() => {
    /*
      登录：
        拼单情况：
          owner -> 需要必选
          friend -> 不需要必选
      未登录或者非拼单情况：
        需要必选
    */
    const itemsCheck = notEnoughRequiredItems.length === 0;
    if (isInGroupBuyMode) return dataKey === 'owner' ? itemsCheck : true;
    return itemsCheck;
  }, [dataKey, isInGroupBuyMode, notEnoughRequiredItems.length]);

  const canCheckout = useMemo(() => {
    const baseCheck = cart.length > 0 && isStoreOpen;
    if (isInGroupBuyMode) {
      return baseCheck && requiredItemsEnough && soldAvailable;
    }
    return baseCheck && requiredItemsEnough && soldAvailable && +subtotal > minimumCharge;
  }, [
    cart,
    isStoreOpen,
    requiredItemsEnough,
    soldAvailable,
    subtotal,
    minimumCharge,
    isInGroupBuyMode,
  ]);

  const buttonText = useMemo(() => {
    if (!isStoreOpen) return '已打烊';
    if (!isInGroupBuyMode && +subtotal < minimumCharge)
      return `差 P${(minimumCharge - subtotal).toFixed(2)} 起送`;
    if (!requiredItemsEnough) return '未点必选品';
    if (!soldAvailable) return '部分售罄';
    return isInGroupBuyMode ? '选好了' : '去下单';
  }, [isStoreOpen, minimumCharge, subtotal, requiredItemsEnough, soldAvailable, isInGroupBuyMode]);

  const discount = (store?.extra.discount || 100) / 100;

  if (!store)
    return (
      <Skeleton.Title
        style={{
          width: "100%",
          marginBottom: 0,
        }}
        className="mx-2"
        animated
      />
    );


  return (
    <>
      <div
        onClick={() =>
          !nonInteractive && cart.length > 0 && setPopupVisible(!popupVisible)
        }
        className="space-x-2 flex items-center"
      >
        <Badge
          style={{ "--right": "10%", "--top": "10%" }}
          content={cart.length}
        >
          <div
            className={classNames(
              "p-2.5 rounded-full",
              canCheckout ? "bg-blue-600" : "bg-gray-600"
            )}
            style={{
              lineHeight: 0,
            }}
          >
            <MallBag
              theme="outline"
              size={24}
              className="relative bottom-0.2"
              fill="#fff"
            />
          </div>
        </Badge>
        <div className="flex flex-col">
          <span className="font-bold text-2xl -mb-1">
            <span className="price-unit text-sm">P</span>
            {(+subtotal).toFixed(2)}
          </span>
          <span className="text-2xs space-x-2">
		  {
			  preferential < subtotal && (
			  <span className="text-red-500">
				预估到手 p{preferential.toFixed(2)}
			  </span>
			  )
		  }
            {
			// discount < 1 && +subtotal >= minimumCharge && (
    //           <span className="text-red-500">
    //             预估到手 p{preferential.toFixed(2)}
    //           </span>
    //         )
			}
            <span className="text-gray-500">
              预估加配送费 P
              {+subtotal >= startPrice
                ? +(deliveryFee || 0)
                : +(oldDeliveryFee || 0)}
            </span>
          </span>
        </div>
      </div>
      <Button
        shape="rounded"
        className={classNames(
          "font-bold text-lg px-6 opacity-100 text-white",
          canCheckout ? "bg-blue-600" : "bg-gray-600"
        )}
        loading={confirmGroupbuyOrderLoading}
        disabled={confirmGroupbuyOrderLoading}
        onClick={() => {
          if (!requiredItemsEnough) {
            Toast.show(
              `未选必选品：${notEnoughRequiredItems
                .map((p) => p.title)
                .join("、")}`
            );
            return;
          }
          if (!canCheckout) return;
          if (isInGroupBuyMode) {
            confirmGroupbuyOrder();
          } else {
            navigate("/checkout");
          }
        }}
        style={{
          height: "46px",
        }}
      >
        {buttonText}
      </Button>
    </>
  );
}

export default CheckoutBar;
