import { Telegram } from '@icon-park/react';
import { useStoreStore } from '../stores/store';

function CustomerServiceButton() {
  const { store } = useStoreStore();

  if (!store?.extra.telegram || !store.extra.phone) return <></>;
  return (
    <a
      target="_blank"
      href={
        (store.extra.telegram ? `https://t.me/${store.extra.telegram}` : "") ||
        `tel:${store.extra.phone}`
      }
      style={{
        lineHeight: 0,
      }}
      className="from-red-500 to-orange-600 bg-gradient-to-tr right-4 p-3 rounded-full"
      rel="noreferrer"
    >
      <Telegram theme="outline" size="28" fill="#fff" />
    </a>
  );
}
export default CustomerServiceButton;
