import type { ReactNode } from 'react';
import { AddOutline } from 'antd-mobile-icons';
import { Button, Stepper, Toast } from 'antd-mobile';
import classNames from 'classnames';
import { useCartStore } from "../stores/cart";
import type { SkuCardUIProps } from './SkuCardUI';
import SkuCardUI from './SkuCardUI';
import { TProduct } from "../react-app-env";

type SkuCardProps = {
  smallImage?: boolean; // 如订单列表应该启用该项
  product: TProduct;
  featured?: boolean; // 开启后价格变红
  stepper?: ReactNode; // 用于控制 stepper 部分
  uiProps?: SkuCardUIProps;
};

function SkuCard({
  product,
  featured = false,
  smallImage = false,
  stepper,
  uiProps = {},
}: SkuCardProps) {
  const { cover, price, title, content, status, specification, id, thumbnails } = product;
  const { setCurrentSelectedProduct, cart, setCart, setPopupVisible } = useCartStore();
  const quantity = cart.filter((p) => p.id === product.id).length;
  const handleAdd = (_event?: any) => {
    if (status === 'soldout') {
      Toast.show('该商品暂时售罄，无法下单');
      return;
    }
    if (specification.length === 0) {
      // 简单商品，直接添加到购物车不弹窗
      setCart([...cart, { id, values: {} }]);
      return;
    }
    setCurrentSelectedProduct(product);
  };

  const handleRemove = (_event?: any) => {
    // SkuCard 减配的前提是商品为简单商品（没有规格），或者数量为 1
    const matchedCartProducts = cart.filter((c) => c.id === product.id);
    if (
      matchedCartProducts.length > 1 &&
      matchedCartProducts.some((c) => c.values && Object.keys(c.values).length > 0)
    ) {
      Toast.show('带规格的商品需要在购物车减购');
      setPopupVisible(true);
      return;
    }
    let removedOnce = false;
    setCart(
      cart.filter((c) => {
        if (c.id === product.id) {
          if (removedOnce) return true;
          removedOnce = true;
          return false;
        }
        return c.id !== product.id;
      }) || [],
    );

    setCart([...cart.filter((p) => p.id !== product.id)]);
  };

  const handleStepperOnChange = (value: number) => {
    if (value < quantity) {
      handleRemove();
    }
    if (value > quantity) {
      handleAdd();
    }
  };

  return (
    <SkuCardUI
      cover={cover}
      soldOut={product.status === 'soldout'}
      title={title}
      content={content}
      price={price}
      thumbnails={thumbnails}
      smallImage={smallImage}
      featured={featured}
      priceRight={
        stepper ||
        (quantity > 0 ? (
          <Stepper value={quantity} inputReadOnly step={1} onChange={handleStepperOnChange} />
        ) : (
          <button
            type="button"
            className={classNames(
              'rounded-full border-none bg-blue-600 p-1 text-white',
              product.status === 'soldout' ? 'bg-gray-400' : '',
            )}
            style={{
              lineHeight: 0,
            }}
            onClick={() => handleAdd()}
          >
            <AddOutline width={14} height={14} />
          </button>
        ))
      }
      {...uiProps}
    />
  );
}

export default SkuCard;
