import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrentUserStore } from '../stores/user';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, setLoginModalVisible, requesting } = useCurrentUserStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user && !requesting) {
      setLoginModalVisible(true);
      if (searchParams.get('noRedirect')?.trim()) {
        navigate('/');
      } else {
        navigate('/', { state: { from: window.location.href.split('#')[1] } });
      }
    }
  }, [user, navigate, setLoginModalVisible, requesting, searchParams]);

  return children;
}

export default RequireAuth;
