import { useEffect, useState } from 'react';
import type {
  TCartProductWithDetail,
  TProduct,
  TSpecificationGroup,
} from "./react-app-env";
import type { TCartProduct } from "./stores/cart";

export const calcSpecsTotal = (
  specification: TSpecificationGroup[],
  // { id: [] | undefined, id2: [] | undefined }
  selectedSpecs: Record<number, number[] | undefined>
) => {
  const productSpecs = specification.map((s) => s.child).flat();
  const specsWithMarkup = productSpecs.filter(
    (s) => typeof s?.markup !== "undefined" && +s.markup > 0
  );
  const specsTotal = Object.keys(selectedSpecs).reduce((acc, key) => {
    const specIds = selectedSpecs[+key];
    if (!specIds) return acc;
    return (
      acc +
      specIds.reduce((acc, specId) => {
        const spec = specsWithMarkup.find((s) => s.id === specId);
        if (!spec) return acc;
        return +spec.markup + acc;
      }, 0)
    );
  }, 0);
  return specsTotal;
};

export const getValuesDetail = (
  product: TProduct,
  values: Record<number, number[] | undefined>
) => {
  return Object.fromEntries(
    Object.entries(values).map(([specId, childIds]) => {
      const spec = product?.specification.find((s) => s.id === +specId);
      // 规格售罄
      return [
        specId,
        childIds?.map((childId) => spec?.child.find((c) => c.id === childId)),
      ];
    })
  );
};

export const getOrderStatusText = (status: number | string) =>
  ["已取消", "已下单", "已接单", "配送中", "已完成"][+status] ?? "未知";

export const getCartByProductWithDetail = (
  cartProducts: TCartProductWithDetail[]
) =>
  cartProducts.reduce((acc, curr) => {
    Array(+curr.quantity)
      .fill(0)
      .forEach(() =>
        acc.push({
          id: curr.id,
          values: Object.fromEntries(
            curr.values.map(({ id, items }) => [id, items.map(({ id }) => id)])
          ),
        })
      );
    return acc;
  }, [] as TCartProduct[]);

export const useProgressiveImage = (src: string) => {
  const [sourceLoaded, setSourceLoaded] = useState<string>("");

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

