import create from 'zustand';
import { persist } from 'zustand/middleware';

import type { TProduct } from "../react-app-env";
import { calcSpecsTotal } from "../utils";

export type TCartProduct = {
  id: number; // 商品 id
  values: Record<number, number[] | undefined>; // 规格选项，id => id[]
};

interface ICartStore {
  currentSelectedProduct?: TProduct;
  cart: TCartProduct[];

  // 指主动唤起的购物车详情弹窗，false 为关闭
  popupVisible: boolean;
  setPopupVisible: (popupVisible: boolean) => void;
  // 拼单模式
  // eslint-disable-next-line no-unused-vars
  setCurrentSelectedProduct: (current?: TProduct) => void;
  // eslint-disable-next-line no-unused-vars
  setCart: (cart?: TCartProduct[]) => void;
  getCartSubTotal: (products: TProduct[]) => number;
  getCartPreTotal: (products: TProduct[]) => number;
}

export const useCartStore = create<ICartStore>()(
  persist(
    (set, get) => ({
      popupVisible: false,
      setPopupVisible: (popupVisible) =>
        set(() => ({
          popupVisible,
        })),

      cart: [],
      setCart: (cart) =>
        set(() => ({
          cart,
        })),

      setCurrentSelectedProduct: (current) =>
        set(() => ({ currentSelectedProduct: current })),
      getCartSubTotal: (products) => {
        const cart = get().cart;
        return cart.reduce((acc, item) => {
          const product = products.find((p) => p.id === item.id);
          const selectedSpecs = item.values;

          if (!product) return acc;

          // 无规格选择情况下直接添加价格
          if (!selectedSpecs) {
            return +product.price + acc;
          }
          return (
            acc +
            (+product.price +
              calcSpecsTotal(product.specification, selectedSpecs))
          );
        }, 0);
      },
	  getCartPreTotal: (products) => {
	    const cart = get().cart;
	    return cart.reduce((acc, item) => {
	      const product = products.find((p) => p.id === item.id);
	      const selectedSpecs = item.values;
	      if (!product) return acc;
		  let finalDiscount:Number = 100;
		  if (product.discount_control) {
			  finalDiscount = product.discount;
		  }
		  if (product.category_discount_control) {
		  	  finalDiscount = product.category_discount;		  
		  }
		  if (product.goods_discount_control) {
		  	  finalDiscount = product.goods_discount;
		  }
		  finalDiscount = Number(finalDiscount)/100;
	      // 无规格选择情况下直接添加价格
	      if (!selectedSpecs) {
	        return +(Number(product.price) * Number(finalDiscount)) + acc;
	      }
	      return (
	        acc +(+(Number(product.price)+Number(calcSpecsTotal(product.specification, selectedSpecs))) * Number(finalDiscount))
	      );
	    }, 0);
	  },
    }),
    {
      name: "takeaway-cart",
    }
  )
);

