import { MallBag } from '@icon-park/react';
import { Badge } from 'antd-mobile';
import { UserOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCartStore } from '../stores/cart';
import CustomerServiceButton from './CustomerServiceButton';

function Toolbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { cart } = useCartStore();
  const { setPopupVisible, popupVisible } = useCartStore();

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const scrollListener = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setShow(true);
      } else {
        setShow(false);
      }
      setLastScrollY(window.scrollY);
    }
  }, [lastScrollY]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', scrollListener);
      // cleanup function
      return () => {
        window.removeEventListener('scroll', scrollListener);
      };
    }
  }, [lastScrollY, scrollListener]);

  return (
    <div
      className={classNames(
        'tooltip fixed bottom-48 space-y-3 right-4 flex-col flex',
        show ? 'hidden' : 'block',
      )}
    >
      <button
        style={{
          lineHeight: 0,
        }}
        onClick={() => navigate('/me')}
        className="me hidden from-black to-gray-600 bg-gradient-to-tr right-4 p-3 rounded-full"
      >
        <UserOutline width={28} height={28} color="#fff" />
      </button>
      {location.pathname === '/' && (
        <Badge
          style={{ '--right': '10%', '--top': '10%' }}
          className="bag hidden"
          content={cart.length}
        >
          <button
            onClick={() => setPopupVisible(!popupVisible)}
            style={{
              lineHeight: 0,
            }}
            className="bag hidden from-black to-gray-600 bg-gradient-to-tr right-4 p-3 rounded-full"
          >
            <MallBag theme="outline" size={28} fill="#fff" />
          </button>
        </Badge>
      )}
      <CustomerServiceButton />
    </div>
  );
}

export default Toolbar;
