import axios from 'axios';
import type {
  TOrder,
  TOrderWithDetail,
  TProduct,
  TRegion,
  TStore,
  TUser,
  TUserAddress,
} from "./react-app-env";
import type {TCartProduct} from './stores/cart';

interface IResult<T> {
    status: number;
    data: T;
    success?: boolean;
    msg?: string;
}


export const validateToken = () =>
  axios
    .post(
      '/token/validate',
      {},
      {
        baseURL: `${window.globals.base || ''}/wp-json/jwt-auth/v1`,
      },
    )
    .then<any>(({ data }) => data);

// 基本信息相关
// {params:{searchkey:'海带丝/包'}}
export const getProductsService = (params: { searchkey: string }) =>
    axios.get('/get-products',{params}).then<IResult<TProduct[]>>(({data}) => data);
export const getStoreService = () =>
    axios.get('/get-store').then<IResult<TStore>>(({data}) => data);
export const getRegionsService = () =>
    axios.get('/get-regions').then<IResult<TRegion[]>>(({data}) => data);

// 用户基本信息相关
export const getCurrentUserService = () =>
    axios.get('/h5-current-user').then<IResult<TUser>>(({data}) => data);
export const getUserAddressService = () =>
    axios.get('/get-user-addresses').then<IResult<TUserAddress[]>>(({data}) => data);
export const getUserOrdersService = () =>
    axios.get('/get-user-orders').then<IResult<TOrder[]> & { total: number }>(({data}) => data);
export const postAddressPublishService = (data: TUserAddress) =>
    axios.post('/address-publish', data).then<IResult<{ id: number }>>(({data}) => data);

// 用户 auth 相关
export const postUsernameLoginService = (data: { username: string; password: string }) =>
    axios.post('/username-login', data).then<IResult<{ token: string }>>(({data}) => data);
export const postPhoneLoginService = (data: { phone: string; code: string }) =>
    axios.post('/phone-login', data).then<IResult<{ token: string }>>(({data}) => data);
export const sendUserVerificationService = (data: { phone?: string }) =>
    axios.post('/send-user-verification', data).then<IResult<{ id: string }>>(({data}) => data);
export const getVerificationTokenService = (data: { phone: string; code: string }) =>
    axios
        .post('/get-verification-token', data)
        .then<IResult<{ id: string; token: string }>>(({data}) => data);
export const modifyPhoneService = (data: { phone?: string; token?: string; code: string }) =>
    axios.post('/modify-phone', data).then<IResult<any>>(({data}) => data);
export const userOutLogin = () =>
    axios.post('/out-login', {}).then<IResult<{}>>(({data}) => data);

// 订单相关
export const updateOrderStatusService = (data: {
    out_trade_no: string;
    action: 'completed' | 'cancelled';
}) => axios.post('/update-user-order-status', data).then<IResult<any>>(({data}) => data);
export default {};
export const getOrderDetailService = (params: { out_trade_no: string }) =>
    axios.get('/get-order-detail', {params}).then<IResult<TOrderWithDetail>>(({data}) => data);
export const postOrderPublishService = (data: {
  name: string;
  tg?: string;
  phone?: string;
  region_id: number;
  address: string;
  user_comment?: string;
  cart: TCartProduct[];
}) =>
  axios
    .post('/order-publish', data)
    .then<IResult<{ id: string; need_confirm: boolean }>>(({ data }) => data);
export const deleteUserOrderService = (data: { out_trade_no: string }) =>
    axios.post('/delete-user-order', data).then<IResult<any>>(({data}) => data);

// 拼单相关
export const postGroupBuyOrderPublishService = (data: { cart: TCartProduct[] }) =>
    axios
        .post('/groupbuy-order-publish', data)
        .then<IResult<{ out_trade_no: string }>>(({data}) => data);
export const postUpdateGroupBuyOrderService = (data: {
    cart: TCartProduct[];
    out_trade_no: string;
    type?: 'owner' | string;
    locked?: boolean;
}) =>
    axios
        .post('/update-groupbuy-order', data)
        .then<IResult<{ out_trade_no: string }>>(({data}) => data);
export const postGroupBuyOrderConfirmService = (data: {
  out_trade_no: string;
  name: string;
  tg?: string;
  phone?: string;
  region_id: number;
  address: string;
  user_comment?: string;
}) =>
  axios
    .post('/groupbuy-order-confirm', data)
    .then<IResult<{ out_trade_no: string; need_confirm: boolean }>>(({ data }) => data);
export const postGroupBuyAcceptRequestService = (data: { out_trade_no: string }) =>
    axios
        .post('/accept-groupbuy-request', data)
        .then<IResult<{ out_trade_no: string; key: string }>>(({data}) => data);
export const postDeleteGroupBuyOrderMemberService = (data: { out_trade_no: string; key: string }) =>
    axios
        .post('/delete-groupbuy-order-member', data)
        .then<IResult<{ out_trade_no: string }>>(({data}) => data);
