import {useRequest} from 'ahooks';
import {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginModal from './components/LoginModal';
import RequireAuth from './components/RequireAuth';
import Toolbar from './components/Toolbar';
import Checkout from './pages/Checkout';
import GroupBuyCheckout from './pages/groupbuy/[outTradeNo]/GroupBuyCheckout';
import GroupBuy from './pages/groupbuy/[outTradeNo]/Index';
import Home from './pages/Home';
import Me from './pages/Me';
import Orders from './pages/orders/Index';
import OrderDetail from './pages/orders/[outTradeNo]';
import {
    getCurrentUserService,
    getProductsService,
    getRegionsService,
    getStoreService,
    validateToken,
} from './services';
import {useAddressBookStore} from './stores/addressBook';
import {useProductsStore} from './stores/products';
import {useRegionsStore} from './stores/regions';
import {useStoreStore} from './stores/store';
import {useCurrentUserStore} from './stores/user';
import './App.css';
import {SafeArea} from 'antd-mobile';
import {useGroupBuyStore} from './stores/groupbuy';
import browserUpdate from "browser-update";

browserUpdate({
    required: {
        e: -2,
        i: 11,
        f: -3,
        o: -3,
        s: 10.1,
        c: 64,
        samsung: 7.0,
    },
    insecure: false,
});

function App() {
    const setProducts = useProductsStore((state) => state.setProducts);
	const setProductsCart = useProductsStore((state) => state.setProductsCart);
    useRequest(getProductsService, {
        // pollingInterval: 3000,
        onSuccess({data, status, msg}) {
            if (status !== 200) {
                console.log(msg);
                return;
            }
            setProducts(data);
			setProductsCart(data);
        },
    });

    const {setStore} = useStoreStore();
    useRequest(getStoreService, {
        // pollingInterval: 3000,
        onSuccess({data, status, msg}) {
            if (status !== 200) {
                console.log(msg);
                return;
            }
            setStore(data);
        },
    });

    const setRegions = useRegionsStore((state) => state.setRegions);
    useRequest(getRegionsService, {
        onSuccess({data, status, msg}) {
            if (status !== 200) {
                console.log(msg);
                return;
            }
            setRegions(data);
        },
    });

    useRequest(validateToken, {
        onError(data) {
            localStorage.removeItem('takeaway-address-book');
            localStorage.removeItem('takeaway-cart');
            localStorage.removeItem('takeaway-groupbuy');
            localStorage.removeItem('user-token');
            //localStorage.clear();
        },
    });

    const {setCurrentGroupBuyOutTradeNo, setDataKey} = useGroupBuyStore();
    const {setUser, setRefreshUserAsync, setRequesting} = useCurrentUserStore();
    const setAddresses = useAddressBookStore((state) => state.setAddresses);
    const {run: authenticate, refreshAsync: refreshUserAsync} = useRequest(getCurrentUserService, {
        manual: true,
        onBefore() {
            setRequesting(true);
        },
        onSuccess({data, status}) {
            if (status !== 200) {
                localStorage.removeItem('user-token');
                setUser(undefined!);
                setAddresses([]);
                return;
            }
            setUser(data);
            if (data.groupbuy_out_trade_no) {
                setDataKey('owner');
                setCurrentGroupBuyOutTradeNo(data.groupbuy_out_trade_no);
            }
        },
        onFinally() {
            setRequesting(false);
        },
    });

    useEffect(() => {
        setRefreshUserAsync(refreshUserAsync);
    }, [setRefreshUserAsync, refreshUserAsync]);

    useEffect(() => {
        const token = localStorage.getItem('user-token');

        if (token || window.globals?.logged_in) {
            authenticate();
        } else {
            setRequesting(false);
        }
    }, [authenticate, setRequesting]);

    const loginModalVisible = useCurrentUserStore((state) => state.loginModalVisible);

    return (
        <>
            <SafeArea position="top"/>
            <main className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route
                        path="/checkout"
                        element={
                            <RequireAuth>
                                <Checkout/>
                            </RequireAuth>
                        }
                    />
                    <Route path="/groupbuy">
                        <Route path=":outTradeNo">
                            <Route index element={<GroupBuy/>}/>
                            <Route
                                path="checkout"
                                element={
                                    <RequireAuth>
                                        <GroupBuyCheckout/>
                                    </RequireAuth>
                                }
                            />
                        </Route>
                    </Route>
                    <Route path="/orders">
                        <Route
                            index
                            element={
                                <RequireAuth>
                                    <Orders/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path=":outTradeNo"
                            element={
                                <RequireAuth>
                                    <OrderDetail/>
                                </RequireAuth>
                            }
                        />
                    </Route>
                    <Route
                        path="/me"
                        element={
                            <RequireAuth>
                                <Me/>
                            </RequireAuth>
                        }
                    />
                </Routes>
                <Toolbar/>
                <LoginModal visible={loginModalVisible}/>
            </main>
            <SafeArea position="bottom"/>
        </>
    );
}

export default App;
