import { Button, Form, Popup, Selector, Skeleton, Stepper, Toast } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useCartStore } from '../stores/cart';
import { useProductsStore } from "../stores/products";
import { calcSpecsTotal } from '../utils';
import SkuCard from './SkuCard';

function ProductDetailPopup() {
  const { currentSelectedProduct, setCart, cart, setCurrentSelectedProduct } = useCartStore();
  const popupVisible = !!currentSelectedProduct;

  const [form] = Form.useForm();
  const [content, setContent] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);

  const selectedSpecs = form.getFieldsValue() as Record<number, number[] | undefined>;
  const price = useMemo(() => {
    if (!currentSelectedProduct) return 0;

    const initialPrice = +currentSelectedProduct.price;

    // 无规格选择情况下直接添加价格
    if (!selectedSpecs || Object.keys(selectedSpecs).length === 0) {
      return initialPrice;
    }

    return initialPrice + calcSpecsTotal(currentSelectedProduct.specification, selectedSpecs);
  }, [currentSelectedProduct, selectedSpecs]);

  const specification = currentSelectedProduct?.specification || [];

  const selectorOnChange = (_e: any) => {
    if (!currentSelectedProduct) return;
    const fieldsValue = form.getFieldsValue();
    const selectedSpecsText: any[] = [];
    Object.entries(fieldsValue).forEach(([key, values]) => {
      const child = specification.find((s) => s.id === +key)?.child;
      if (Array.isArray(values)) {
        selectedSpecsText.push(...values.map((v) => child?.find((c) => c.id === +v)?.name));
      }
    });
    setContent(`已选：${selectedSpecsText.join('、')}`);
  };

  useEffect(() => {
    if (!popupVisible) {
      form.resetFields();
      setQuantity(1);
    }
  }, [form, popupVisible]);

  return (
    <Popup
      visible={popupVisible}
      className={classNames(popupVisible ? 'popup-visible' : 'popup-invisible')}
      onMaskClick={() => setCurrentSelectedProduct(undefined!)}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '66vh',
        height: '100%',
      }}
    >
      {currentSelectedProduct ? (
        <>
          <SkuCard
            featured
            product={currentSelectedProduct}
            uiProps={{
              content: content || currentSelectedProduct.content,
              price: price || currentSelectedProduct.price,
            }}
            stepper={
              <Stepper
                value={quantity}
                inputReadOnly
                min={1}
                step={1}
                onChange={(q) => setQuantity(q)}
              />
            }
          />
          <Form
            form={form}
            initialValues={Object.fromEntries(
              specification
                .filter(({ attribute }) => attribute.includes('required'))
                .map(({ id, child }) => [id, [child[0].id]]),
            )}
            className="flex-1 mb-12 overflow-y-scroll flex-shrink"
            style={{
              '--border-top': 'none',
              '--border-bottom': 'none',
            }}
            onFinish={(values: any) => {
              setCart([
                ...cart,
                ...Array(quantity)
                  .fill(0)
                  .map(() => ({
                    id: currentSelectedProduct.id,
                    values,
                  })),
              ]);

              form.resetFields();
              setCurrentSelectedProduct();
            }}
            onFinishFailed={({ errorFields }) => Toast.show(errorFields?.[0].errors[0])}
          >
            {specification.map(({ id, name, attribute, child }) => {
              const isMultiple = attribute?.includes('multiple');
              const isRequired = attribute?.includes('required');
              return (
                <div className="mt-4 space-y-8" key={id}>
                  <div>
                    <h4 className="text-xs text-gray-600 mb-2">
                      {name}
                      {isMultiple ? '（多选）' : ''}
                    </h4>
                    <Form.Item
                      name={id}
                      noStyle
                      rules={
                        isRequired
                          ? [
                              {
                                required: true,
                                message: `请选择${name}`,
                              },
                            ]
                          : []
                      }
                    >
                      <Selector
                        multiple={isMultiple}
                        onChange={selectorOnChange}
                        options={child
                          .map((c) => ({
                            ...c,
                            // 继承情况覆写
                            status: c.status === 'inherit' ? c.inherit_status : c.status,
                          }))
                          // 只有售罄和上架显示
                          .filter(({ status }) => ['on', 'soldout'].includes(status))
                          .map(({ name, markup, id, status }) => ({
                            label: (
                              <span key={id}>
                                {name}{' '}
                                {+markup > 0 && <span className="text-red-600"></span>}
                              </span>
							  // P{markup}
                            ),
                            value: id,
                            disabled: status !== 'on',
                          }))}
                      />
                    </Form.Item>
                  </div>
                </div>
              );
            })}
          </Form>
          <Button
            color="primary"
            shape="rounded"
            className="place-self-end"
            block
            onClick={() => form.submit()}
          >
            加入购物车
          </Button>
        </>
      ) : (
        <Skeleton />
      )}
      <button
        type="button"
        className="bg-gray-500 rounded-full p-1 absolute right-4 top-4"
        onClick={() => setCurrentSelectedProduct()}
        style={{
          lineHeight: 0,
        }}
      >
        <CloseOutline color="#fff" fontSize={16} />
      </button>
    </Popup>
  );
}

export default ProductDetailPopup;
