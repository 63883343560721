import create from 'zustand';
import { persist } from 'zustand/middleware';
import type { TUserAddress } from "../react-app-env";

interface IAddressBookStore {
  selectedAddressId: number;
  setSelectedAddressId: (addressId: number) => void;

  addresses: TUserAddress[];
  setAddresses: (addresses: TUserAddress[]) => void;
}

export const useAddressBookStore = create<IAddressBookStore>()(
  persist(
    (set) => ({
      selectedAddressId: -1,
      setSelectedAddressId: (selectedAddressId) =>
        set(() => ({
          selectedAddressId,
        })),

      addresses: [],
      setAddresses: (addresses: TUserAddress[]) => set(() => ({ addresses })),
    }),
    {
      name: "takeaway-address-book",
    }
  )
);

