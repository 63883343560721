import React from 'react';
import {Button, Modal, NavBar, Skeleton, Toast} from 'antd-mobile';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {useRequest} from 'ahooks';

import SkuCardUI from '../../components/SkuCardUI';
import {getUserOrdersService, updateOrderStatusService} from '../../services';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import {getOrderStatusText} from '../../utils';
import {sum} from 'lodash';
import HeaderBar from '../../components/HeaderBar';

function Orders() {
    const {data, loading: ordersLoading, refresh} = useRequest(getUserOrdersService);
    const {runAsync: updateOrderStatus, loading} = useRequest(updateOrderStatusService, {
        manual: true,
        onSuccess({status, msg}) {
            if (status !== 200) {
                Toast.show({
                    icon: 'fail',
                    content: msg,
                });
                return;
            }
            refresh();
            Toast.show({
                icon: 'success',
                content: msg,
            });
        },
    });
    const navigate = useNavigate();

    return (
      <div className="others">
        <HeaderBar>我的订单</HeaderBar>
        <div className="bg-gray-100 min-h-screen py-4 space-y-4">
          {ordersLoading ? (
            <LoadingSkeleton />
          ) : (
            data?.data.map(
              ({ created_at, status, subtotal, detail, out_trade_no, delivery_fee }) => (
                <section key={out_trade_no} className="p-4 bg-white space-y-4">
                  <div className="flex justify-between text-xs">
                    <time>{dayjs(created_at).format('YYYY-MM-DD HH:mm:ss')}</time>
                    <span>{getOrderStatusText(status)}</span>
                  </div>
                  <div
                    onClick={() => navigate(`/orders/${out_trade_no}`)}
                    className="space-y-6 cursor-pointer"
                  >
                    {Array.isArray(detail) ? (
                      <>
                        {detail.map(({ quantity, title, cover, price, values, id }) => {
                          return (
                            <SkuCardUI
                              key={[id, title, JSON.stringify(values)].join('')}
                              title={title}
                              cover={cover}
                              content={
                                values && values.length > 0
                                  ? `已选：${values
                                      .map((v: any) => v.items.map((i: any) => i.name))
                                      .flat()
                                      .join('、')}`
                                  : ''
                              }
                              price={price}
                              smallImage
                              priceRight={<span className="text-xs">x{quantity}</span>}
                            />
                          );
                        })}
                        <p className="text-right text-xs">
                          共 {sum(detail.map(({ quantity }) => quantity))} 件，订单合计：
                          <span className=" font-bold text-lg">
                            <span className="text-2xs">P</span>
                            {Number(subtotal) + Number(delivery_fee)}
                          </span>
                        </p>
                        {[1].includes(+status) && (
                          <div className="border-t-gray-200 border-t pt-4 flex justify-end space-x-2">
                            <Button
                              type="button"
                              loading={loading}
                              onClick={() => {
                                Modal.confirm({
                                  content: '您确定要取消订单吗？',
                                  onConfirm: async () => {
                                    await updateOrderStatus({
                                      out_trade_no,
                                      action: 'cancelled',
                                    });
                                  },
                                });
                              }}
                              className="min-w-22"
                              size="small"
                            >
                              取消订单
                            </Button>
                            {+status === 3 && (
                              <Button
                                type="button"
                                color="primary"
                                loading={loading}
                                onClick={() => {
                                  Modal.confirm({
                                    content: '您确定要确认订单吗？',
                                    onConfirm: async () => {
                                      await updateOrderStatus({
                                        out_trade_no,
                                        action: 'completed',
                                      });
                                    },
                                  });
                                }}
                                className="min-w-22"
                                size="small"
                                fill="outline"
                              >
                                确认订单
                              </Button>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <p className="text-center my-4 text-base text-gray-600">无法获取订单信息</p>
                    )}
                  </div>
                </section>
              ),
            )
          )}
        </div>
      </div>
    );
}

export default Orders;
