import { useRequest } from 'ahooks';
import { Button, Modal, Toast } from 'antd-mobile';
import { useNavigate, useParams } from "react-router-dom";
import { TCartProductWithDetail } from "../react-app-env";
import { postDeleteGroupBuyOrderMemberService } from "../services";
import { useCartStore } from "../stores/cart";
import { useGroupBuyStore } from "../stores/groupbuy";
import { useCurrentUserStore } from "../stores/user";
import { getCartByProductWithDetail } from '../utils';
import SkuCardsWithSubtotal from './SkuCardsWithSubtotal';

function GroupBuyFriendSection({
  dataKey,
  data,
  canManage = true,
}: {
  dataKey: string;
  data: TCartProductWithDetail[];
  canManage?: boolean;
}) {
  const { outTradeNo } = useParams();
  const { setCart } = useCartStore();
  const { user } = useCurrentUserStore();
  const { setCurrentGroupBuyOutTradeNo, setDataKey, dataKey: currentDataKey } = useGroupBuyStore();
  const navigate = useNavigate();
  const isOwner = user?.groupbuy_out_trade_no === outTradeNo;
  const _canManage = canManage && (currentDataKey === dataKey || isOwner);

  const { loading, runAsync: deleteMember } = useRequest(postDeleteGroupBuyOrderMemberService, {
    manual: true,
    onSuccess({ status, msg }) {
      if (status !== 200) {
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        return;
      }
      Toast.show({
        icon: 'success',
        content: msg,
      });
    },
  });

  const id = dataKey === 'owner' ? 1 : +dataKey.split('friend_')[1];
  return (
    <section className="p-4 mt-4 space-y-4 bg-white">
      <div className="flex justify-between items-center">
        <h3 className="font-bold text-base">
          {id} 号{+id === 1 ? '发起人' : '拼单人'}
        </h3>
        <div className="flex items-center space-x-2">
          {_canManage && isOwner && dataKey !== 'owner' && (
            <Button
              size="mini"
              onClick={() => {
                Modal.confirm({
                  title: '删除拼单人',
                  content: `是否删除 ${id} 号拼单人？`,
                  cancelText: '再等等',
                  confirmText: '确认删除',
                  onConfirm: async () => {
                    await deleteMember({
                      out_trade_no: outTradeNo!,
                      key: dataKey,
                    });
                  },
                });
              }}
              shape="rounded"
              loading={loading}
              fill="outline"
            >
              删除
            </Button>
          )}
          {_canManage && (
            <Button
              size="mini"
              onClick={() => {
                setCart(getCartByProductWithDetail(data));
                setCurrentGroupBuyOutTradeNo(outTradeNo!);
                setDataKey(dataKey);
                navigate('/');
              }}
              shape="rounded"
              color="primary"
              fill="outline"
            >
              选择商品
            </Button>
          )}
        </div>
      </div>
      <SkuCardsWithSubtotal data={data} />
    </section>
  );
}

export default GroupBuyFriendSection;
