import { Card, Image, Modal, Swiper, Tag } from 'antd-mobile';
import { UserOutline } from 'antd-mobile-icons';
import { useMemo } from 'react';
import { Link } from "react-router-dom";
import { TStore } from "../react-app-env";
import { useGroupBuyStore } from '../stores/groupbuy';
import { useStoreStore } from '../stores/store';
import { useCurrentUserStore } from '../stores/user';
import LoadingSkeleton from './LoadingSkeleton';

type IStoreDetailProps = {
  data: TStore;
};

function StoreDetail({ data }: IStoreDetailProps) {
  const { logo, status, title, notice } = data;
  return (
    <div className="flex space-x-4">
      <Image width={48} height={48} className="rounded" src={logo} fit="cover" />
      <div className="flex-1 overflow-hidden">
        <h3 className="font-bold mb-1 text-xl">{title}</h3>
        <div className="flex space-x-2 items-center">
          {status === 'open' ? (
            <Tag color="primary" fill="outline">
              营业中
            </Tag>
          ) : (
            <Tag color="default" fill="outline">
              已打烊
            </Tag>
          )}
          <p className="whitespace-nowrap text-ellipsis overflow-hidden text-gray-600 text-xs">
            {notice}
          </p>
        </div>
      </div>
    </div>
  );
}

function TopSlider() {
  const store = useStoreStore((state) => state.store);
  const { requesting, user } = useCurrentUserStore();
  const { currentGroupBuyOutTradeNo } = useGroupBuyStore();
  const banners = store?.banners;

  const loggedIn = !!(requesting === false && user);
  const shouldDisplayMeButton = useMemo(() => {
    // 未登录 -> 没有 otn -> 显示
    // 登录 -> 有 otn 并且 user === otn -> 显示
    // 登录 -> 有 otn 但是 user !== otn -> 不显示
    // 登录 -> 没有 otn -> 显示

    if (!loggedIn) {
      return !currentGroupBuyOutTradeNo === true;
    }
    if (!currentGroupBuyOutTradeNo) return true;
    return user.groupbuy_out_trade_no === currentGroupBuyOutTradeNo;
  }, [loggedIn, user, currentGroupBuyOutTradeNo]);


  if (!store || !banners) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="top-slider relative">
      {shouldDisplayMeButton && (
        <Link className="me absolute right-2 top-2 z-10 p-3 text-white" to="/me">
          <UserOutline width={24} height={24} />
        </Link>
      )}
      <Swiper
        indicatorProps={{
          color: 'white',
        }}
        loop
        defaultIndex={1}
      >
        {banners?.map(({ image, url }) => (
          <Swiper.Item key={image} onClick={() => url && window.open(url)}>
            <div
              className="h-64 bg-cover bg-center flex flex-col-reverse"
              style={{
                backgroundImage: `url(${image})`,
				backgroundSize:'100% 100%',
              }}
            />
          </Swiper.Item>
        ))}
      </Swiper>
      <div className="store-meta absolute w-full bottom-0">
        <Card
          onClick={() => {
            Modal.show({
              content: (
                <div className="flex flex-col space-y-4">
                  <StoreDetail data={store} />
                  <p>
                    公告：
                    {store.notice}
                  </p>
                  <p>
                    地址：
                    {store.extra.address}
                  </p>
                  <p>
                    电话：
                    <a target="_blank" href={`tel:${store.extra.phone}`} rel="noreferrer">
                      {store.extra.phone}
                    </a>
                  </p>
                  <p>
                    Telegram：
                    <a
                      target="_blank"
                      href={`https://t.me/${store.extra.telegram}`}
                      rel="noreferrer"
                    >
                      {store.extra.telegram}
                    </a>
                  </p>
                </div>
              ),
              showCloseButton: false,
              closeOnMaskClick: true,
            });
          }}
          className="m-4"
        >
          <StoreDetail data={store} />
        </Card>
      </div>
    </div>
  );
}

export default TopSlider;
