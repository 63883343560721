import { useRequest, useToggle } from 'ahooks';
import { Button, Form, Input, Modal, Toast } from 'antd-mobile';
import { CloseOutline, EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons';
import { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { postPhoneLoginService, postUsernameLoginService } from "../services";
import { useCurrentUserStore } from '../stores/user';
import SendVerificationCodeButton from './SendVerificationButton';

function UsernameLoginForm({ onSwitch }: { onSwitch: () => void }) {
  const refreshUserAsync = useCurrentUserStore((state) => state.refreshUserAsync);
  const setLoginModalVisible = useCurrentUserStore((state) => state.setLoginModalVisible);
  const [pwVisible, setPwVisible] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { run: login, loading } = useRequest(postUsernameLoginService, {
    manual: true,
    async onSuccess({ status, msg, data }) {
      if (status !== 200) {
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        return;
      }
      localStorage.setItem('user-token', data.token);
      await refreshUserAsync();
      Toast.show({
        icon: 'success',
        content: msg || '登录成功',
      });
      setLoginModalVisible(false);
      form.resetFields();
      setPwVisible(false);
      if (location.state && (location.state as any).from) {
        navigate((location.state as any).from || '/');
      } else {
        navigate('/');
      }
    },
  });

  return (
    <Form
      form={form}
      onFinish={(e) => login(e)}
      onFinishFailed={({ errorFields: [{ errors }] }) => {
        Toast.show(errors[0]);
      }}
      layout="horizontal"
    >
      <div className="space-y-4">
        <Form.Item noStyle label="用户名" rules={[{ required: true }]} name="username">
          <Input placeholder="输入用户名，用户不存在将自动创建" className="bg-gray-200 px-4 py-3" />
        </Form.Item>
        <div className="relative">
          <span
            className="absolute right-0 p-4 text-gray-600"
            onClick={() => setPwVisible(!pwVisible)}
          >
            {pwVisible ? (
              <EyeOutline width={18} height={18} />
            ) : (
              <EyeInvisibleOutline width={18} height={18} />
            )}
          </span>
          <Form.Item
            noStyle
            label="密码"
            rules={[{ required: true }, { type: 'string', min: 6 }]}
            name="password"
          >
            <Input
              placeholder="输入密码"
              type={pwVisible ? 'text' : 'password'}
              className="bg-gray-200 px-4 py-3"
            />
          </Form.Item>
        </div>

        <Button block color="primary" loading={loading} type="submit" className="font-bold py-3">
          立即登录
        </Button>
        <Button fill="none" onClick={() => onSwitch()} block>
          手机号登录
        </Button>
      </div>
    </Form>
  );
}

function PhoneLoginForm({ onSwitch }: { onSwitch: () => void }) {
  const refreshUserAsync = useCurrentUserStore((state) => state.refreshUserAsync);
  const setLoginModalVisible = useCurrentUserStore((state) => state.setLoginModalVisible);
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [phone, setPhone] = useState<string>('');
  const { run: login, loading } = useRequest(postPhoneLoginService, {
    manual: true,
    async onSuccess({ status, msg, data }) {
      if (status !== 200) {
        Toast.show({
          icon: 'fail',
          content: msg,
        });
        return;
      }
      localStorage.setItem('user-token', data.token);
      await refreshUserAsync();
      setLoginModalVisible(false);
      form.resetFields();
      if (location.state && (location.state as any).from) {
        navigate((location.state as any).from || '/');
      } else {
        navigate('/');
      }
      Toast.show({
        icon: 'success',
        content: msg || '登录成功',
      });
    },
  });

  return (
    <Form
      onFinish={(values) => {
        login(values);
      }}
      form={form}
      onFinishFailed={({ errorFields: [{ errors }] }) => {
        Toast.show(errors[0]);
      }}
    >
      <div className="space-y-4">
        <Form.Item noStyle label="手机号" name="phone" required>
          <Input
            placeholder="输入手机号"
            onChange={(e) => setPhone(e)}
            className="bg-gray-200 px-4 py-3"
          />
        </Form.Item>
        <div className="flex">
          <Form.Item noStyle label="验证码" name="code" required>
            <Input
              placeholder="输入验证码"
              className="bg-gray-200 px-4 py-3 mr-2"
              type="text"
            />
          </Form.Item>
          <SendVerificationCodeButton phone={phone} />
        </div>

        <Button
          block
          loading={loading}
          type="submit"
          color="primary"
          className="font-bold py-3"
        >
          立即登录
        </Button>
        <Button fill="none" onClick={() => onSwitch()} block>
          用户名登录
        </Button>
      </div>
    </Form>
  );
}

function LoginModal({ visible }: { visible: boolean }) {
  const [method, { toggle }] = useToggle(false);
  const setLoginModalVisible = useCurrentUserStore((state) => state.setLoginModalVisible);

  return (
    <Modal
      visible={visible}
      content={
        <>
          <div className="px-5 py-3">
            <h3 className="font-bold text-2xl mb-4">{method ? '用户名登录' : '手机号登录'}</h3>
            {method ? (
              <UsernameLoginForm onSwitch={toggle} />
            ) : (
              <PhoneLoginForm onSwitch={toggle} />
            )}
          </div>
          <button
            onClick={() => setLoginModalVisible(false)}
            type="button"
            className="absolute -bottom-12 mx-auto left-0 right-0  w-7 h-7 rounded-full bg-white flex items-center justify-center"
          >
            <CloseOutline className="text-gray-600" />
          </button>
        </>
      }
    />
  );
}

export default LoginModal;
