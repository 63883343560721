import create from 'zustand';
import { persist } from 'zustand/middleware';

interface IGroupBuyStore {
  currentGroupBuyOutTradeNo?: string;
  setCurrentGroupBuyOutTradeNo: (outTradeNo: string) => void;
  setDataKey: (dataKey: string) => void;
  dataKey?: string;
}

export const useGroupBuyStore = create<IGroupBuyStore>()(
  persist(
    (set) => ({
      setCurrentGroupBuyOutTradeNo: (outTradeNo: string) =>
        set(() => ({ currentGroupBuyOutTradeNo: outTradeNo })),
      setDataKey: (dataKey: string) => set(() => ({ dataKey })),
    }),
    {
      name: 'takeaway-groupbuy',
    },
  ),
);

