import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import axios from "axios";
import App from "./App";

import "./virtual:windi.css";
import "./index.css";

import "@vant/touch-emulator";

axios.defaults.baseURL = `${window.globals.base || ""}/wp-json/takeaway/v1/`;
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user-token");
    if (!token) return config;
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);
ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
