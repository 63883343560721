import classNames from 'classnames';
import FooterBar from "./FooterBar";
import ProductDetailPopup from './ProductDetailPopup';
import CartDetailPopup from "./CartDetailPopup";
import CheckoutBar from './CheckoutBar';

function BottomBar({ className }: { className?: string }) {
  return (
    <FooterBar className={classNames("z-30 bottom-bar", className)}>
      <div className="bottom-bar-inner flex items-center justify-between">
        <CheckoutBar />
        <ProductDetailPopup />
        <CartDetailPopup />
      </div>
    </FooterBar>
  );
}
export default BottomBar;
