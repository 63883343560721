import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, NavBar, TextArea, Toast } from "antd-mobile";
import { useRequest } from "ahooks";

import FooterBar from "../../../components/FooterBar";
import { useCartStore } from "../../../stores/cart";
import { useProductsStore } from "../../../stores/products";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useRegionsStore } from "../../../stores/regions";
import { useAddressBookStore } from "../../../stores/addressBook";
import AddressBook from "../../../components/AddressBook";
import {
  getOrderDetailService,
  postGroupBuyOrderConfirmService,
  postUpdateGroupBuyOrderService,
} from "../../../services";
import LoadingSkeleton from "../../../components/LoadingSkeleton";
import { useCurrentUserStore } from "../../../stores/user";
import HeaderBar from "../../../components/HeaderBar";
import GroupBuyFriendSection from "../../../components/GroupBuyFriendSection";
import { sum } from "lodash";
import { useGroupBuyStore } from "../../../stores/groupbuy";
import { useStoreStore } from "../../../stores/store";
import { TCartProductWithDetail } from "../../../react-app-env";

function GroupBuyCheckout() {
  const products = useProductsStore((state) => state.products);
  const { setCart, getCartSubTotal } = useCartStore();
  const { store } = useStoreStore();
  const subtotal = useMemo(
    () => getCartSubTotal(products).toFixed(2),
    [getCartSubTotal, products]
  );
  const { refreshUserAsync, user } = useCurrentUserStore();
  const regions = useRegionsStore((state) => state.regions);
  const { addresses: userAddresses, selectedAddressId } = useAddressBookStore();
  const {
    setDataKey,
    setCurrentGroupBuyOutTradeNo,
    currentGroupBuyOutTradeNo,
  } = useGroupBuyStore();
  const clearGroupBuyCache = () => {
    setDataKey(undefined!);
    setCurrentGroupBuyOutTradeNo(undefined!);
  };

  const { outTradeNo } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { loading, data } = useRequest(
    () => getOrderDetailService({ out_trade_no: outTradeNo! }),
    {
      ready: !!outTradeNo,
      onSuccess({ status, msg }) {
        if (status !== 200) {
          Toast.show({
            icon: "fail",
            content: msg,
          });
          if (outTradeNo === currentGroupBuyOutTradeNo) {
            clearGroupBuyCache();
          }
          navigate("/", { replace: true });
          return;
        }
      },
    }
  );

  const minimumCharge = +(store?.extra.minimum_charge || 0);
  const { deliveryFee, oldDeliveryFee, startPrice } = useMemo(() => {
    const selectedAddress = userAddresses.find(
      (a) => a.id === selectedAddressId
    );
    const region = regions.find((r) => r.id === selectedAddress?.region_id);
    return {
      deliveryFee: region?.delivery_fee || 0,
      oldDeliveryFee: region?.old_delivery_fee || 0,
      startPrice: region?.start_price || minimumCharge,
    };
  }, [regions, selectedAddressId, userAddresses, minimumCharge]);

  const finalDeliveryFee = useMemo(() => {
    const selectedAddress = userAddresses.find(
      (a) => a.id === selectedAddressId
    );

    if (selectedAddressId > 0 && !selectedAddress?.region_id) return 999;
    return +subtotal >= startPrice ? deliveryFee : oldDeliveryFee;
  }, [
    subtotal,
    startPrice,
    deliveryFee,
    oldDeliveryFee,
    userAddresses,
    selectedAddressId,
  ]);
  
  const discount = (store?.extra.discount || 100) / 100;

  const { run: confirmOrder, loading: confirmLoading } = useRequest(
    postGroupBuyOrderConfirmService,
    {
      manual: true,
      onSuccess: async (e) => {
        if (e.status !== 200) {
          Toast.show({
            icon: "fail",
            content: e.msg,
          });
          return;
        }
        await refreshUserAsync();
        setCurrentGroupBuyOutTradeNo(undefined!);
        setDataKey(undefined!);
        Toast.show({
          icon: "success",
          content: e.msg,
        });
        navigate("/");
        form.resetFields();
        setCart([]);

        if (e.data.need_confirm) {
          Modal.show({
            title: "配送费确认",
            content: "请先与我们联系，确认配送地址和配送费",
            closeOnMaskClick: false,
            closeOnAction: true,
            actions: [
              {
                key: "ok",
                text: "知道了",
                primary: true,
                onClick: () => {
                  if (store) {
                    window.open(
                      (store.extra.telegram
                        ? `https://t.me/${store.extra.telegram}`
                        : "") || `tel:${store.extra.phone}`
                    );
                  }
                },
              },
            ],
          });
        }
      },
    }
  );

  const { runAsync: updateAsync } = useRequest(postUpdateGroupBuyOrderService, {
    manual: true,
    ready: !!outTradeNo,
    onSuccess({ status, msg }) {
      if (status !== 200) {
        Toast.show({
          icon: "fail",
          content: msg,
        });
        return;
      }
    },
  });

  if (!outTradeNo) return <Navigate to="/" replace />;

  const order = data?.data;
  const total = order?.detail
    ? sum(
        Object.values(order?.detail)
          .filter((v) => typeof v !== "boolean")
          .map((arr: any) =>
            sum(arr.map((d: TCartProductWithDetail) => +d.price * d.quantity))
          )
      )
    : 0;
  return (
    <div className="others">
      <HeaderBar
        navbarProps={{
          right: null,
          onBack: () => {
            Modal.confirm({
              title: "返回商品编辑",
              content: `返回后订单将解锁，只有您一人可以编辑商品。是否继续？`,
              onConfirm: async () => {
                await updateAsync({
                  out_trade_no: outTradeNo,
                  locked: false,
                  cart: [],
                });
                navigate(`/groupbuy/${outTradeNo}`);
              },
            });
          },
        }}
      >
        确认订单
      </HeaderBar>
      <div className="bg-gray-100 min-h-screen pb-24">
        <AddressBook />
        {products.length === 0 || !order || loading ? (
          <LoadingSkeleton />
        ) : (
          <Form
            form={form}
            onFinish={(e) => {
              const selectedAddress = userAddresses.find(
                (a) => a.id === selectedAddressId
              );
              if (!selectedAddress || userAddresses.length === 0) {
                Toast.show({
                  icon: "fail",
                  content: "请选择收货地址",
                });
                return;
              }
              confirmOrder({
                out_trade_no: outTradeNo,
                ...selectedAddress,
                user_comment: e.user_comment,
              });
            }}
            layout="vertical"
          >
            <Form.Header> </Form.Header>
            <GroupBuyFriendSection
              canManage={false}
              dataKey="owner"
              data={order.detail.owner}
            />
            {Object.entries(order.detail)
              .filter(([k, _]) => k.startsWith("friend_"))
              .map(([k, v]) => (
                <>
                  <Form.Header> </Form.Header>
                  <GroupBuyFriendSection
                    canManage={false}
                    key={k}
                    dataKey={k}
                    data={v as TCartProductWithDetail[]}
                  />
                </>
              ))}
            <Form.Header> </Form.Header>
            <div className="cell px-4">
              <div>配送费用</div>
              <div className="text-xsm">
                {oldDeliveryFee !== finalDeliveryFee && (
                  <span className="text-gray-400 line-through">
                    P{oldDeliveryFee}
                  </span>
                )}
                <span className="font-bold">P{finalDeliveryFee}</span>
              </div>
            </div>
            <Form.Header> </Form.Header>
            <div className="cell flex-wrap px-4">
              <div className="mb-2">订单备注</div>
              <Form.Item noStyle label="订单备注" name="user_comment">
                <TextArea
                  rows={4}
                  className="bg-gray-100 w-full rounded p-2 mt-2 text-xs"
                  placeholder="请备注信息"
                />
              </Form.Item>
            </div>
          </Form>
        )}
      </div>
      <FooterBar>
        <div className="bottom-bar-inner flex items-center justify-between">
          <div>
            <span className="text-xsm">优惠后合计：</span>
            <span className="text-red-600 font-bold">
              <span className="price-unit text-sm">P</span>
              <span className="text-lg">
                {(total * discount + Number(finalDeliveryFee)).toFixed(2)}
              </span>
            </span>
          </div>
          {outTradeNo === user?.groupbuy_out_trade_no && (
            <Button
              shape="rounded"
              onClick={() => form.submit()}
              type="submit"
              loading={confirmLoading}
              disabled={total < minimumCharge}
              className="font-bold text-lg px-6"
              color="primary"
              style={{
                height: "46px",
              }}
            >
              {total >= minimumCharge
                ? "提交订单"
                : `差 P${(minimumCharge - total).toFixed(2)} 起送`}
            </Button>
          )}
        </div>
      </FooterBar>
    </div>
  );
}

export default GroupBuyCheckout;
