import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  NavBar,
  TextArea,
  Toast,
} from "antd-mobile";
import { useRequest } from "ahooks";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useCurrentUserStore } from "../../../stores/user";
import {
  deleteUserOrderService,
  getOrderDetailService,
  postDeleteGroupBuyOrderMemberService,
  postGroupBuyAcceptRequestService,
  postUpdateGroupBuyOrderService,
} from "../../../services";
import LoadingSkeleton from "../../../components/LoadingSkeleton";
import classNames from "classnames";
import FooterBar from "../../../components/FooterBar";
import { useGroupBuyStore } from "../../../stores/groupbuy";
import create from "zustand";
import { sum } from "lodash";
import GroupBuyFriendSection from "../../../components/GroupBuyFriendSection";
import HeaderBar from "../../../components/HeaderBar";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  TCartProductWithDetail,
  TOrderWithDetail,
} from "../../../react-app-env";
import { useStoreStore } from "../../../stores/store";

const useOrderStore = create<{
  order?: TOrderWithDetail;
  setOrder: (order: TOrderWithDetail) => void;
  getFriendsCount: () => number;
}>((set, get) => ({
  setOrder: (order) => set(() => ({ order })),
  getFriendsCount: () => {
    const order = get().order;
    if (!order) return 0;
    return Object.keys(order.detail).filter((k) => k.startsWith("friend_"))
      .length;
  },
}));

function GroupBuy() {
  const { outTradeNo } = useParams();
  const navigate = useNavigate();
  const { refreshUserAsync, user, requesting } = useCurrentUserStore();
  const { setOrder, order, getFriendsCount } = useOrderStore();
  const { store } = useStoreStore()
  const {
    setDataKey,
    setCurrentGroupBuyOutTradeNo,
    currentGroupBuyOutTradeNo,
    dataKey,
  } = useGroupBuyStore();
  const { runAsync: cancel, loading: deleteLoading } = useRequest(
    deleteUserOrderService,
    {
      manual: true,
      async onSuccess({ status, msg }) {
        if (status !== 200) {
          Toast.show({
            icon: "fail",
            content: msg,
          });
          return;
        }
        await refreshUserAsync();
        setDataKey(undefined!);
        setCurrentGroupBuyOutTradeNo(undefined!);
        Toast.show({
          icon: "success",
          content: msg,
        });
        navigate(`/`);
      },
    }
  );

  const { runAsync: acceptAsync } = useRequest(
    postGroupBuyAcceptRequestService,
    {
      manual: true,
      ready: !!outTradeNo,
      onSuccess({ status, msg, data }) {
        if (status !== 200) {
          Toast.show({
            icon: "fail",
            content: msg,
          });
          return;
        }
        Toast.show({
          icon: "success",
          content: msg,
        });
        setCurrentGroupBuyOutTradeNo(data.out_trade_no);
        setDataKey(data.key);
      },
    }
  );

  // 访客提示是否拼单
  const isOwner = outTradeNo === user?.groupbuy_out_trade_no;
  const owner = order?.detail.owner;

  const clearGroupBuyCache = () => {
    setDataKey(undefined!);
    setCurrentGroupBuyOutTradeNo(undefined!);
  };

  const [called, setCalled] = useState<boolean>(false);
  const { loading, refresh } = useRequest(
    () => getOrderDetailService({ out_trade_no: outTradeNo! }),
    {
      ready: !!outTradeNo && !requesting,
      pollingInterval: 3000,
      onSuccess({ status, msg, data }) {
        if (status !== 200) {
          Toast.show({
            icon: "fail",
            content: msg,
          });
          if (outTradeNo === currentGroupBuyOutTradeNo) {
            clearGroupBuyCache();
          }
          navigate("/", { replace: true });
          return;
        }
        setOrder(data);
        const isCurrentOTN = outTradeNo === currentGroupBuyOutTradeNo;

        // 锁订单情况
        if (data.detail.locked) {
          if (outTradeNo === user?.groupbuy_out_trade_no) {
            navigate(`/groupbuy/${outTradeNo}/checkout`);
          } else {
            Toast.show("发起人已提交订单");
            clearGroupBuyCache();
            navigate("/", { replace: true });
          }
          return;
        }

        // 其它拼单情况
        if (!called && currentGroupBuyOutTradeNo && !isCurrentOTN) {
          setCalled(true);
          Modal.confirm({
            title: "存在进行中拼单",
            content: "您可能有其它拼单尚未完成，是否接受新拼单？",
            cancelText: "再想想",
            confirmText: "接受新拼单",
            onCancel: () => {
              navigate("/", {
                replace: true,
              });
            },
            onConfirm: async () => {
              await acceptAsync({ out_trade_no: outTradeNo! });
            },
          });
          return;
        }

        if (!called && !currentGroupBuyOutTradeNo) {
          setCalled(true);
          Modal.confirm({
            content: "邀请你参与拼单，是否接受？",
            cancelText: "再想想",
            confirmText: "接受",
            onCancel: () => {
              navigate("/", {
                replace: true,
              });
            },
            onConfirm: async () => {
              await acceptAsync({ out_trade_no: outTradeNo! });
            },
          });
          return;
        }

        if (isCurrentOTN && dataKey && !data.detail[dataKey]) {
          Toast.show("发起人已将您从拼单中移除");
          clearGroupBuyCache();
          navigate("/", { replace: true });
          return;
        }
      },
    }
  );

  const { runAsync: updateAsync, loading: updateOrderDetailLoading } =
    useRequest(postUpdateGroupBuyOrderService, {
      manual: true,
      ready: !!outTradeNo,
      onSuccess({ status, msg }) {
        if (status !== 200) {
          Toast.show({
            icon: "fail",
            content: msg,
          });
          return;
        }
        refresh();
      },
    });

  const { runAsync: deleteMember } = useRequest(
    postDeleteGroupBuyOrderMemberService,
    {
      manual: true,
      onSuccess({ status, msg }) {
        if (status !== 200) {
          Toast.show({
            icon: "fail",
            content: msg,
          });
          return;
        }
        clearGroupBuyCache();
        navigate("/", { replace: true });
        Toast.show({
          icon: "success",
          content: msg,
        });
      },
    }
  );

  useEffect(() => {
    refreshUserAsync();
  }, [refreshUserAsync]);

  if (!outTradeNo) return <Navigate to="/" />;

  const total = order?.detail
    ? sum(
        Object.values(order?.detail)
          .filter((v) => typeof v !== "boolean")
          .map((arr: any) =>
            sum(arr.map((d: TCartProductWithDetail) => +d.price * d.quantity))
          )
      )
    : 0;

  const discount = store?.extra.discount || 100;

  return (
    <div className="others">
      <HeaderBar
        navbarProps={{
          onBack: () => navigate("/", { replace: true }),
          right: null,
        }}
      >
        拼单详情
      </HeaderBar>
      <div className="bg-gray-100 pt-4 pb-24">
        {(loading && !order) || !owner ? (
          <LoadingSkeleton />
        ) : (
          <div className="space-y-4">
            <section className="p-4 bg-white">
              {isOwner ? (
                <div className="text-right">
                  <Button
                    size="mini"
                    onClick={() => {
                      Modal.confirm({
                        title: "确认关闭拼单",
                        content: `${
                          getFriendsCount() > 0
                            ? `已有 ${getFriendsCount()} 位好友参与拼单，`
                            : ""
                        }确认关闭吗？`,
                        onConfirm: async () => {
                          await cancel({ out_trade_no: outTradeNo });
                        },
                      });
                    }}
                    loading={deleteLoading}
                    color="default"
                    className="px-0"
                    fill="none"
                  >
                    取消拼单
                  </Button>
                </div>
              ) : (
                <div className="text-right">
                  <Button
                    size="mini"
                    onClick={() => {
                      Modal.confirm({
                        title: "确认退出拼单",
                        content: `您将退出拼单，是否继续？`,
                        onConfirm: async () => {
                          await deleteMember({
                            out_trade_no: outTradeNo,
                            key: dataKey!,
                          });
                        },
                      });
                    }}
                    loading={deleteLoading}
                    color="default"
                    className="px-0"
                    fill="none"
                  >
                    退出拼单
                  </Button>
                </div>
              )}

              <GroupBuyStatusSection />
              <CopyToClipboard
                onCopy={() => {
                  Toast.show({
                    icon: "success",
                    content: "复制成功",
                  });
                }}
                text={window.location.href}
              >
                <Button
                  color="primary"
                  shape="rounded"
                  className="font-bold"
                  block
                >
                  复制链接，邀请好友
                </Button>
              </CopyToClipboard>
              <p className="text-xs text-center py-2 text-gray-600">
                分享链接给好友，立即发起拼单
              </p>
            </section>
            <GroupBuyFriendSection dataKey="owner" data={owner} />
            {order?.detail &&
              Object.entries(order?.detail)
                .filter(([k, _]) => k.startsWith("friend_"))
                .map(([k, v]) => (
                  <GroupBuyFriendSection
                    key={k}
                    dataKey={k}
                    data={v as TCartProductWithDetail[]}
                  />
                ))}
          </div>
        )}
      </div>
      <FooterBar>
        <div className="bottom-bar-inner flex items-center justify-between">
          <div
            className="space-x-2 flex items-center"
            style={{
              height: "46px",
            }}
          >
            <span className="text-xsm">优惠后合计：</span>
            <span className="text-red-600 font-bold">
              <span className="price-unit text-sm">P</span>
              <span className="text-lg">{(+total * discount / 100).toFixed(2)}</span>
            </span>
          </div>
          {isOwner && (
            <Button
              shape="rounded"
              onClick={() => {
                Modal.confirm({
                  title: "确认提交拼单",
                  content: "结算时订单会被锁定，好友将无法继续添加商品",
                  confirmText: "去结算",
                  cancelText: "再等等",
                  onConfirm: async () => {
                    await updateAsync({
                      type: "owner",
                      out_trade_no: outTradeNo,
                      locked: true,
                      cart: [],
                    });
                    navigate(`/groupbuy/${outTradeNo}/checkout`);
                  },
                });
              }}
              type="submit"
              loading={updateOrderDetailLoading}
              className="font-bold text-lg px-6"
              color="primary"
              style={{
                height: "46px",
              }}
            >
              提交拼单
            </Button>
          )}
        </div>
      </FooterBar>
    </div>
  );
}

function GroupBuyStatusSection() {
  const { order, getFriendsCount } = useOrderStore();

  return (
    <div className="flex flex-wrap gap-2 justify-center items-center mb-6">
      <div className="w-14 h-14 rounded-full bg-blue-100 flex justify-center items-center">
        <span className="text-2xl font-bold text-blue-600">1</span>
      </div>
      {Array(getFriendsCount())
        .fill(0)
        .map((_, index) => {
          const id = index + 2;
          const data = order?.detail[`friend_${id}`] || [];
          return (
            <div
              key={id}
              className={classNames(
                "w-14 h-14 rounded-full flex justify-center items-center",
                Array.isArray(data) && data.length > 0
                  ? "bg-blue-100"
                  : "bg-gray-200"
              )}
            >
              <span
                className={classNames(
                  "text-2xl font-bold",
                  Array.isArray(data) && data.length > 0
                    ? "text-blue-600"
                    : "text-gray-600"
                )}
              >
                {id}
              </span>
            </div>
          );
        })}
    </div>
  );
}

export default GroupBuy;
