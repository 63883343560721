import { Skeleton } from 'antd-mobile';

function LoadingSkeleton() {
  return (
    <section className="space-y-4 p-4 bg-white">
      <Skeleton.Paragraph />
    </section>
  );
}

export default LoadingSkeleton;
